import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .input-container {
    position: relative;
    align-items: center;
    .inputButtons {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 12px;
      height: 40px;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }
    .min-button-loading {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      height: 40px;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      cursor: not-allowed
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }

    .input{
      padding: 0.375rem 0.75rem;
      background-color: green;
    }

    .input.is-invalid {
      background-image: none;
      border: 2px solid var(--ds-border-neutral, #dfe1e6);
      border-bottom: 2px solid #cf1919 !important ;
      &:focus {
        box-shadow: none;
      }
      padding: -0.375rem -0.75rem !important;
    }
  }

  .toggle {
    display: flex;
    justify-items: center;
    align-items: center;
    height: 60px;
    margin-left: 1.4em;
    width: 100%;
  }
  /* display: flex;
  justify-content: center;
  align-items: center; */

  .inputButtons {
    float: right;
    height: 40px;
    line-height: 40px;
    margin-top: -42px;
    align-items: center;
    justify-content: center;
    border-radius: 0px 3px 3px 0px;
    background-color: ${({ theme: { colors } }) => colors.nav}!important;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;
`;

export const ConteinerRadio = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  min-width: 155px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border: solid 2px #dfe1e6;
`;
