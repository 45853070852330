import { formaEmissao } from './formaEmissao';

export const defaultGeral = {
  tipo_emissao_nfe: formaEmissao[0],
  des_motivo_contingencia: '',
  dta_ini_contingencia: null,
  arquivos: '',
  num_certificado: '',
  casas_decimais: { label: '2', value: 2 },
  orientacao: { label: 'Retrato', value: 0 },
  cod_ser_impresso: { label: 'EAN', value: 0 },
  pos_canhoto: { label: 'Cabeçalho ', value: 0 },
  num_copias: { label: '1 ', value: 1 },
  num_fonte_razao: { label: '8 ', value: 8 },
  flg_exibe_trib_prod: false,
  flg_exibe_dados_ref: false,
};
