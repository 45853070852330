import * as yup from 'yup';

export const schemaOperador = yup
  .object({
    cod_operador: yup.number().required().min(0).positive(),
    des_operador: yup.string().required().max(25),
    busca_parceiro: yup.object().required(),
    finalizadora: yup
      .object({
        label: yup.string().required(),
        cod_finalizadora: yup.number().required(),
      })
      .required(),
  })
  .required();
