import { GridRowParams } from '@material-ui/data-grid';
import FormDefault from '~/components/FormDefault';
import InputSelect from '~/components/Inputs/InputSelect';
import InputText from '~/components/Inputs/InputText';
import Loja from '~/components/Loja';
import {
  InputDate,
  InputFilesLabel,
  InputSelect as InputSelectNovo,
  InputText as InputTextNovo,
} from '~/components/NovosInputs';
import Search from '~/components/Search';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import api from '~/services/api';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Container } from './styles';
import { IFormData } from './types';
import { defaultGeral } from './utils/defaultValues';
import { formaEmissao, FormaEmissao } from './utils/formaEmissao';
import { formataDataMotivo } from './utils/formatDataMotivo';
import useAuth from '~/hooks/useAuth';
import { schema } from './Validations';
import { yupResolver } from '@hookform/resolvers/yup';

const ParametrizacaoNfe: React.FC = () => {
  const user = useAuth();
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchAmbiente = watch('ambiente');

  const [inicializado, setInitInicializado] = useState(false);
  const [uploadFile, setUploadFile] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [arquivo, setArquivo] = useState<any>(undefined);
  const [formData, setFormData] = useState<IFormData>({
    cod_loja: {
      value: undefined,
      isRequired: true,
      isInvalid: true,
    },
    num_certificado: {
      value: undefined,
      isRequired: true,
      isInvalid: true,
    },
    senha_certificado: {
      value: undefined,
      isRequired: true,
      isInvalid: true,
    },
    tipo: {
      value: undefined,
      isRequired: true,
      isInvalid: true,
    },
    orientacao: {
      value: { label: 'Retrato', value: 0 },
      isRequired: true,
      isInvalid: false,
    },
    casas_decimais: {
      value: { label: '2', value: 2 },
      isRequired: true,
      isInvalid: false,
    },
    cod_ser_impresso: {
      value: { label: 'EAN', value: 0 },
      isRequired: true,
      isInvalid: false,
    },
    pos_canhoto: {
      value: { label: 'Cabeçalho ', value: 0 },
      isRequired: true,
      isInvalid: false,
    },
    num_copias: {
      value: { label: '1 ', value: 1 },
      isRequired: true,
      isInvalid: false,
    },
    num_fonte_razao: {
      value: { label: '8 ', value: 8 },
      isRequired: true,
      isInvalid: false,
    },
    flg_exibe_trib_prod: {
      value: false,
      isRequired: true,
      isInvalid: false,
    },
    flg_exibe_dados_ref: {
      value: false,
      isRequired: true,
      isInvalid: false,
    },
  });

  const [idRegistro, setidRegistro] = useState<number | undefined>(undefined);
  const returnValuesLabelsEntreNum: any = (
    startingNumber: number,
    finalNumber: number,
  ) => {
    const arrayValueLabel = [];
    while (startingNumber !== finalNumber + 1) {
      arrayValueLabel.push({
        label: `${startingNumber}`,
        value: startingNumber,
      });
      startingNumber++;
    }
    return arrayValueLabel;
  };

  const tipoEmissaoNfe: FormaEmissao = watch('tipo_emissao_nfe');

  const disableMotivoInicio = !(
    tipoEmissaoNfe === undefined ||
    (tipoEmissaoNfe && tipoEmissaoNfe.value !== 0)
  );

  const tipoCertificadoOpcoes = [
    { label: 'A1', value: 0 },
    { label: 'A3 (Wincrypt)', value: 1 },
  ];

  const ambienteOpcoes = [
    { label: 'Nenhum', value: -1 },
    { label: 'Produção', value: 0 },
    { label: 'Homologação', value: 1 },
  ];

  const orientacaoOpcoes = [
    { label: 'Retrato', value: 0 },
    { label: 'Paisagem', value: 1 },
  ];

  const casasDecimaisOpcoes = returnValuesLabelsEntreNum(2, 4);

  const codSerImpressoOpcoes = [
    { label: 'EAN', value: 0 },
    { label: 'PLU', value: 1 },
  ];

  const posCanhotoOpcoes = [
    { label: 'Cabeçalho ', value: 0 },
    { label: 'Rodapé', value: 1 },
  ];

  const numCopiasOpcoes = returnValuesLabelsEntreNum(1, 4);

  const numFonteRazaoOpcoes = returnValuesLabelsEntreNum(6, 18);

  const handleChangeNumCertificado = useCallback(
    (val: string, isInvalid: boolean) => {
      setFormData({
        ...formData,
        num_certificado: { ...formData.num_certificado, value: val, isInvalid },
      });

      setValue('num_certificado', val);
    },
    [formData, setValue],
  );

  const handleChangeTipo = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        tipo: { ...formData.tipo, value: val, isInvalid },
      });

      setValue('tipo', val);
    },
    [formData, setValue],
  );

  const handleChangeSenhaCertificado = useCallback(
    (val: string, isInvalid: boolean) => {
      setFormData({
        ...formData,
        senha_certificado: {
          ...formData.senha_certificado,
          value: val,
          isInvalid,
        },
      });

      setValue('senha_certificado', val);
    },
    [formData, setValue],
  );

  const handleChangeOrientacao = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        orientacao: { ...formData.orientacao, value: val, isInvalid },
      });
      setValue('orientacao', val);
    },
    [formData, setValue],
  );

  const handleChangeCasasDecimais = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        casas_decimais: { ...formData.casas_decimais, value: val, isInvalid },
      });

      setValue('casas_decimais', val);
    },
    [formData, setValue],
  );

  const handleChangeCodSerImpresso = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        cod_ser_impresso: {
          ...formData.cod_ser_impresso,
          value: val,
          isInvalid,
        },
      });

      setValue('cod_ser_impresso', val);
    },
    [formData, setValue],
  );

  const handleChangePosCanhoto = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        pos_canhoto: {
          ...formData.pos_canhoto,
          value: val,
          isInvalid,
        },
      });

      setValue('pos_canhoto', val);
    },
    [formData, setValue],
  );

  const handleChangeNumCopias = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        num_copias: {
          ...formData.num_copias,
          value: val,
          isInvalid,
        },
      });

      setValue('num_copias', val);
    },
    [formData, setValue],
  );

  const handleChangeFonteRazaoSocial = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        num_fonte_razao: {
          ...formData.num_fonte_razao,
          value: val,
          isInvalid,
        },
      });

      setValue('num_fonte_razao', val);
    },
    [formData, setValue],
  );

  const onNew = () => {
    resetFormData();
  };

  const resetFormData = useCallback(() => {
    setInitInicializado(false);
    setUpdate(false);
    setFormData({
      cod_loja: {
        value: user.user?.loja,
        isRequired: true,
        isInvalid: false,
      },
      num_certificado: {
        value: undefined,
        isRequired: true,
        isInvalid: true,
      },
      senha_certificado: {
        value: undefined,
        isRequired: true,
        isInvalid: true,
      },
      tipo: {
        value: { label: 'Informe o tipo', value: undefined },
        isRequired: true,
        isInvalid: true,
      },

      orientacao: {
        value: { label: 'Retrato', value: 0 },
        isRequired: true,
        isInvalid: false,
      },
      casas_decimais: {
        value: { label: '2', value: 2 },
        isRequired: true,
        isInvalid: false,
      },
      cod_ser_impresso: {
        value: { label: 'EAN', value: 0 },
        isRequired: true,
        isInvalid: false,
      },
      pos_canhoto: {
        value: { label: 'Cabeçalho ', value: 0 },
        isRequired: true,
        isInvalid: false,
      },
      num_copias: {
        value: { label: '1 ', value: 1 },
        isRequired: true,
        isInvalid: false,
      },
      num_fonte_razao: {
        value: { label: '8 ', value: 8 },
        isRequired: true,
        isInvalid: false,
      },
      flg_exibe_trib_prod: {
        value: false,
        isRequired: true,
        isInvalid: false,
      },
      flg_exibe_dados_ref: {
        value: false,
        isRequired: true,
        isInvalid: false,
      },
    });
    setArquivo(undefined);
    reset();
    reset(defaultGeral);
    setValue('orientacao', { label: 'Retrato', value: 0 });
    setValue('casas_decimais', { label: '2', value: 2 });
    setValue('cod_ser_impresso', { label: 'EAN', value: 0 });
    setValue('pos_canhoto', { label: 'Cabeçalho ', value: 0 });
    setValue('num_copias', { label: '1 ', value: 1 });
    setValue('num_fonte_razao', { label: '8 ', value: 8 });
    setValue('flg_exibe_trib_prod', false);
    setValue('flg_exibe_dados_ref', false);
    setValue('tipo_emissao_nfe', formaEmissao[0]);
  }, [reset, setValue, user.user?.loja]);

  const onRowClick = async (param: GridRowParams) => {
    const { row } = param;
    const {
      cod_loja,
      num_casas_decimais,
      num_certificado,
      num_copias,
      num_fonte_razao,
      senha_certificado,
      tipo_ambiente,
      tipo_certificado,
      tipo_cod_produto,
      tipo_orientacao,
      tipo_pos_canhoto,
      flg_exibe_trib_prod,
      flg_exibe_dados_ref,
      des_tipo_ambiente,
      tipo_emissao_nfe,
      dta_ini_contingencia,
      des_motivo_contingencia,
    } = row;

    const emissaoNfe = formaEmissao.find((f) => f.value === tipo_emissao_nfe);
    const dtaCont = dta_ini_contingencia
      ? formataDataMotivo(dta_ini_contingencia)
      : null;

    setValue('tipo_emissao_nfe', emissaoNfe);
    setValue('dta_ini_contingencia', dtaCont);
    setValue('des_motivo_contingencia', des_motivo_contingencia);
    setValue('num_certificado', num_certificado);
    setidRegistro(cod_loja);

    setValue('ambiente', {
      label: des_tipo_ambiente,
      value: tipo_ambiente,
    });
    setValue('senha_certificado', senha_certificado);

    setValue('tipo', {
      label: tipo_certificado === 0 ? 'A1' : 'A3 (Wincrypt)',
      value: tipo_certificado,
    });
    setValue('orientacao', {
      label: tipo_orientacao === 0 ? 'Retrato' : 'Paisagem',
      value: tipo_orientacao,
    });
    setValue('casas_decimais', {
      label: `${num_casas_decimais}`,
      value: num_casas_decimais,
    });
    setValue('cod_ser_impresso', {
      label: tipo_cod_produto === 0 ? 'EAN' : 'PLU',
      value: tipo_cod_produto,
    });
    setValue('pos_canhoto', {
      label: tipo_pos_canhoto === 0 ? 'Cabeçalho' : 'Rodapé',
      value: tipo_pos_canhoto,
    });
    setValue('num_copias', { label: `${num_copias}`, value: num_copias });
    setValue('num_fonte_razao', {
      label: `${num_fonte_razao}`,
      value: num_fonte_razao,
    });
    setValue('flg_exibe_trib_prod', flg_exibe_trib_prod);
    setValue('flg_exibe_dados_ref', flg_exibe_dados_ref);

    setFormData({
      cod_loja: {
        value: cod_loja,
        isRequired: true,
        isInvalid: false,
      },
      num_certificado: {
        value: num_certificado,
        isRequired: true,
        isInvalid: false,
      },
      senha_certificado: {
        value: senha_certificado,
        isRequired: true,
        isInvalid: false,
      },
      tipo: {
        value: {
          label: tipo_certificado === 0 ? 'A1' : 'A3 (Wincrypt)',
          value: tipo_certificado,
        },
        isRequired: true,
        isInvalid: false,
      },
      orientacao: {
        value: {
          label: tipo_orientacao === 0 ? 'Retrato' : 'Paisagem',
          value: tipo_orientacao,
        },
        isRequired: true,
        isInvalid: false,
      },
      casas_decimais: {
        value: { label: `${num_casas_decimais}`, value: num_casas_decimais },
        isRequired: true,
        isInvalid: false,
      },
      cod_ser_impresso: {
        value: {
          label: tipo_cod_produto === 0 ? 'EAN' : 'PLU',
          value: tipo_cod_produto,
        },
        isRequired: true,
        isInvalid: false,
      },
      pos_canhoto: {
        value: {
          label: tipo_pos_canhoto === 0 ? 'Cabeçalho' : 'Rodapé',
          value: tipo_pos_canhoto,
        },
        isRequired: true,
        isInvalid: false,
      },
      num_copias: {
        value: { label: `${num_copias}`, value: num_copias },
        isRequired: true,
        isInvalid: false,
      },
      num_fonte_razao: {
        value: { label: `${num_fonte_razao}`, value: num_fonte_razao },
        isRequired: true,
        isInvalid: false,
      },
      flg_exibe_trib_prod: {
        value: flg_exibe_trib_prod,
        isRequired: true,
        isInvalid: false,
      },
      flg_exibe_dados_ref: {
        value: flg_exibe_dados_ref,
        isRequired: true,
        isInvalid: false,
      },
    });
    setUpdate(true);
    setShowSearch(false);
  };

  const onSave = handleSubmit(async (dataForm) => {
    if (!dataForm.tipo_emissao_nfe) {
      setError('tipo_emissao_nfe', { type: 'required' });
      return;
    }

    if (
      dataForm.tipo_emissao_nfe?.value !== 0 &&
      (!dataForm.des_motivo_contingencia || !dataForm.dta_ini_contingencia)
    ) {
      setError('des_motivo_contingencia', { type: 'required' });
      setError('dta_ini_contingencia', { type: 'required' });
    }

    if (
      dataForm.des_motivo_contingencia &&
      dataForm.des_motivo_contingencia.length >= 0 &&
      dataForm.des_motivo_contingencia.length < 15
    ) {
      setError('des_motivo_contingencia', { type: 'required' });
      toast.warning('Motivo deve ter no mínimo 15 caracteres informados');
    }

    if (
      dataForm.des_motivo_contingencia &&
      dataForm.des_motivo_contingencia.length >= 15
    )
      clearErrors('des_motivo_contingencia');
    if (dataForm.dta_ini_contingencia) clearErrors('dta_ini_contingencia');

    if (formData.cod_loja.value === undefined) {
      return toast.warning('Ao menos uma loja deve estar selecionada');
    }

    if (!dataForm.tipo_emissao_nfe) {
      setError('tipo_emissao_nfe', { type: 'required' });
      return;
    }

    if (dataForm.tipo_emissao_nfe && dataForm.tipo_emissao_nfe.value !== 0) {
      if (dataForm.des_motivo_contingencia.length >= 15)
        clearErrors('des_motivo_contingencia');

      if (dataForm.dta_ini_contingencia) clearErrors('dta_ini_contingencia');

      if (!dataForm.des_motivo_contingencia && !dataForm.dta_ini_contingencia) {
        setError('des_motivo_contingencia', { type: 'required' });
        setError('dta_ini_contingencia', { type: 'required' });
        return;
      }

      if (!dataForm.des_motivo_contingencia && dataForm.dta_ini_contingencia) {
        setError('des_motivo_contingencia', { type: 'required' });
        clearErrors('dta_ini_contingencia');
        return;
      }

      if (
        dataForm.des_motivo_contingencia &&
        dataForm.des_motivo_contingencia.length >= 0 &&
        dataForm.des_motivo_contingencia.length < 15
      ) {
        setError('des_motivo_contingencia', { type: 'required' });
        toast.warning('Motivo deve ter no mínimo 15 caracteres informados');
        return;
      }

      if (dataForm.des_motivo_contingencia && !dataForm.dta_ini_contingencia) {
        setError('dta_ini_contingencia', { type: 'required' });
        clearErrors('des_motivo_contingencia');
        return;
      }
    }

    if (isUpdate) {
      const { data } = await api.put(`/certificadodigital/${idRegistro}`, {
        num_certificado: dataForm.num_certificado,
        senha_certificado: dataForm.senha_certificado,
        tipo_certificado: dataForm.tipo.value,
        tipo_ambiente: dataForm.ambiente.value,
        tipo_orientacao: dataForm.orientacao.value,
        num_casas_decimais: dataForm.casas_decimais.value,
        num_copias: dataForm.num_copias.value,
        tipo_pos_canhoto: dataForm.pos_canhoto.value,
        num_fonte_razao: dataForm.num_fonte_razao.value,
        tipo_cod_produto: dataForm.cod_ser_impresso.value,
        flg_exibe_trib_prod: dataForm.flg_exibe_trib_prod,
        flg_exibe_dados_ref: dataForm.flg_exibe_dados_ref,
        tipo_emissao_nfe: dataForm.tipo_emissao_nfe?.value ?? 0,
        des_motivo_contingencia: dataForm.des_motivo_contingencia,
        dta_ini_contingencia: dataForm.dta_ini_contingencia || null,
      });
      if (data.success) {
        if (arquivo) {
          salvarArquivo(arquivo);
        }
        toast.success(data.message);
        setUpdate(false);
        resetFormData();
        setShowSearch(true);
      }
    } else {
      const { data } = await api.post('/certificadodigital', {
        cod_loja: formData.cod_loja?.value,
        num_certificado: dataForm.num_certificado,
        senha_certificado: dataForm.senha_certificado,
        tipo_certificado: dataForm.tipo.value,
        tipo_ambiente: dataForm.ambiente.value,
        tipo_orientacao: dataForm.orientacao.value,
        num_casas_decimais: dataForm.casas_decimais.value,
        num_copias: dataForm.num_copias.value,
        tipo_pos_canhoto: dataForm.pos_canhoto.value,
        num_fonte_razao: dataForm.num_fonte_razao.value,
        tipo_cod_produto: dataForm.cod_ser_impresso.value,
        tipo_emissao_nfe: dataForm.tipo_emissao_nfe?.value ?? 0,
        des_motivo_contingencia: dataForm.des_motivo_contingencia,
        dta_ini_contingencia: dataForm.dta_ini_contingencia || null,
        flg_exibe_trib_prod: dataForm.flg_exibe_trib_prod,
        flg_exibe_dados_ref: dataForm.flg_exibe_dados_ref,
      });
      if (data.success) {
        if (arquivo) {
          salvarArquivo(arquivo);
        }
        toast.success(data.message);
        resetFormData();
        setShowSearch(false);
      }
    }
  });

  const handleDelete = async () => {
    try {
      const res = await api.delete(`/certificadodigital/${idRegistro}`);
      const { success, message } = res.data;
      if (!success) throw new Error(message);
      setUpdate(false);
      resetFormData();
      toast.success(message);
      setShowSearch(true);
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  const salvarArquivo = async (arquivos: any) => {
    if (arquivos.length > 0) {
      try {
        const formDataUpload = new FormData();
        formDataUpload.append('cod_loja', String(formData.cod_loja.value));

        for (let i = 0; i < arquivos.length; i++) {
          formDataUpload.append('arquivos', arquivos[i]);
        }
        await api.post(`/certificadodigital/salvar-arquivo/`, formDataUpload);

        setUploadFile(false);
      } catch (e: any) {
        toast.warn(e);
      }
    }
    setUploadFile(false);
  };

  useEffect(() => {
    if (tipoEmissaoNfe?.value === 0) reset(defaultGeral);
  }, [reset, tipoEmissaoNfe?.value]);

  useEffect(() => {
    if (!isUpdate) reset(defaultGeral);
  }, [isUpdate, reset]);

  return (
    <Container>
      {showSearch && (
        <Search
          newData={() => setShowSearch(false)}
          onRowClick={onRowClick}
          codTela={41}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={41}
          title="Parametrização NF-e"
          codigoRegistro={[{ value: idRegistro, des_campo: 'Código' }]}
          onSave={onSave}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          isUpdate={isUpdate}
          onNew={() => onNew()}
          onDelete={handleDelete}
          onClearFields={() => resetFormData()}
          onReturnSearch={() => {
            setShowSearch(true);
            setUpdate(false);
            resetFormData();
          }}
          showSwitch={false}
        >
          <Row>
            <Col
              md={12}
              sm={12}
              className={
                formData.cod_loja.isInvalid === true &&
                formData.cod_loja.isRequired === true &&
                inicializado === true
                  ? 'invalidInput'
                  : ''
              }
            >
              <Loja
                selectedLoja={
                  Number(formData.cod_loja.value) || user.user?.loja
                }
                isDisabled={isUpdate}
                onChange={(val) => {
                  setFormData({
                    ...formData,
                    cod_loja: {
                      ...formData.cod_loja,
                      value: Number(val),
                      isInvalid: false,
                    },
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={12}>
              <InputText
                autoComplete="off"
                autoFocus
                label="Nº de Série"
                value={formData.num_certificado.value}
                minLength={1}
                maxLength={50}
                isDisabled={false}
                placeholder="Informe o Nº de Série do Certificado"
                isEmpty
                isRequired
                setInvalid={!!errors.num_certificado}
                iniInicializado={!!errors.num_certificado}
                onChange={(newValue: string, isInvalid = true) => {
                  handleChangeNumCertificado(newValue, isInvalid);
                }}
              />
            </Col>

            <Col md={4} sm={12}>
              {/* retirando preenchimento automatico  */}
              <input
                id="password"
                autoComplete="new-password"
                name="password"
                type="password"
                style={{
                  display: 'none',
                  font: "14px 'Segoe UI', sans-serif",
                }}
              />
              <InputText
                label="Senha do Certificado"
                type="password"
                value={formData.senha_certificado.value}
                minLength={1}
                maxLength={50}
                placeholder="Informe a Senha"
                isEmpty
                isRequired={formData.senha_certificado.isRequired}
                setInvalid={formData.senha_certificado.isInvalid}
                iniInicializado={inicializado}
                onChange={(newValue: string, isInvalid = true) => {
                  handleChangeSenhaCertificado(newValue, isInvalid);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <InputSelect
                label="Tipo"
                placeholder="Informe o Tipo"
                value={formData.tipo.value}
                options={tipoCertificadoOpcoes}
                isRequired={formData.tipo.isRequired}
                setInvalid={formData.tipo.isInvalid}
                iniInicializado={inicializado}
                onChange={(
                  newValue: { label: string; value: number },
                  isInvalid = true,
                ) => {
                  handleChangeTipo(newValue, isInvalid);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <InputSelect
                label="Ambiente"
                placeholder="Informe o Ambiente"
                value={watchAmbiente}
                options={ambienteOpcoes}
                isRequired
                setInvalid={!!errors.ambiente}
                iniInicializado={!!errors.ambiente}
                onChange={(newValue: { label: string; value: number }) => {
                  setValue('ambiente', newValue);
                }}
              />
            </Col>
          </Row>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              height: '80px',
            }}
          >
            <Col
              md={4}
              sm={12}
              onClick={() => {
                if (formData.cod_loja.value === undefined) {
                  toast.warning(
                    'Antes de escolher o arquivo, selecione uma loja primeiro',
                  );
                }
              }}
              style={{
                overflow: 'hidden', // Adicionando overflow hidden para evitar que o filho extrapole
                position: 'relative', // Adicionando position relative para alinhar o elemento filho absolutamente
              }}
            >
              <InputFilesLabel
                accept=".pfx"
                disabled={formData.cod_loja.value === undefined}
                isError={false}
                loading={uploadFile}
                name="arquivos"
                placeholder="Escolher Arquivo"
                register={register}
                control={control}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (
                    e.currentTarget.files &&
                    e.currentTarget.files?.length > 0
                  )
                    setArquivo(e.currentTarget.files);
                }}
              />
              {arquivo && arquivo.length > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: 20,
                    right: 0,
                    marginRight: '12px',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'flex-end',
                    width: '100%',
                    whiteSpace: 'nowrap',
                    color: '#AAAAAA',
                  }}
                >
                  <small>Arquivo {`${arquivo[0]?.name}`} carregado.</small>
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <div>
              <Separator labelText="Parâmetros DANFE" color="#6B778c" />
            </div>

            <Col md={3} sm={12}>
              <InputSelect
                label="Orientação"
                placeholder="Informe a orientação"
                value={formData.orientacao.value}
                options={orientacaoOpcoes}
                isRequired={formData.orientacao.isRequired}
                setInvalid={formData.orientacao.isInvalid}
                iniInicializado={inicializado}
                onChange={(
                  newValue: { label: string; value: number },
                  isInvalid = true,
                ) => {
                  handleChangeOrientacao(newValue, isInvalid);
                }}
              />
            </Col>
            <Col className="casasContainer" md={2} sm={12}>
              <InputSelect
                label="Nº de Casas Decimais"
                placeholder=""
                value={formData.casas_decimais.value}
                options={casasDecimaisOpcoes}
                isRequired={formData.casas_decimais.isRequired}
                setInvalid={formData.casas_decimais.isInvalid}
                iniInicializado={inicializado}
                onChange={(
                  newValue: { label: string; value: number },
                  isInvalid = true,
                ) => {
                  handleChangeCasasDecimais(newValue, isInvalid);
                }}
              />
            </Col>
            <Col className="casasContainer" md={2} sm={12}>
              <InputSelect
                label="Código a Ser Impresso"
                placeholder=""
                value={formData.cod_ser_impresso.value}
                options={codSerImpressoOpcoes}
                isRequired={formData.cod_ser_impresso.isRequired}
                setInvalid={formData.cod_ser_impresso.isInvalid}
                iniInicializado={inicializado}
                onChange={(
                  newValue: { label: string; value: number },
                  isInvalid = true,
                ) => {
                  handleChangeCodSerImpresso(newValue, isInvalid);
                }}
              />
            </Col>
            <Col md={3} sm={12}>
              <InputSelect
                label="Posição Canhoto"
                placeholder=""
                value={formData.pos_canhoto.value}
                options={posCanhotoOpcoes}
                isRequired={formData.pos_canhoto.isRequired}
                setInvalid={formData.pos_canhoto.isInvalid}
                iniInicializado={inicializado}
                onChange={(
                  newValue: { label: string; value: number },
                  isInvalid = true,
                ) => {
                  handleChangePosCanhoto(newValue, isInvalid);
                }}
              />
            </Col>
            <Col md={2} sm={12}>
              <InputSelect
                label="Nº de Cópias"
                placeholder=""
                value={formData.num_copias.value}
                options={numCopiasOpcoes}
                isRequired={formData.num_copias.isRequired}
                setInvalid={formData.num_copias.isInvalid}
                iniInicializado={inicializado}
                onChange={(
                  newValue: { label: string; value: number },
                  isInvalid = true,
                ) => {
                  handleChangeNumCopias(newValue, isInvalid);
                }}
              />
            </Col>
            <Col md={3} sm={12}>
              <InputSelect
                label="Fonte Razão Social"
                placeholder=""
                value={formData.num_fonte_razao.value}
                options={numFonteRazaoOpcoes}
                isRequired={formData.num_fonte_razao.isRequired}
                setInvalid={formData.num_fonte_razao.isInvalid}
                iniInicializado={inicializado}
                onChange={(
                  newValue: { label: string; value: number },
                  isInvalid = true,
                ) => {
                  handleChangeFonteRazaoSocial(newValue, isInvalid);
                }}
              />
            </Col>
            <Col md={3} sm={12}>
              <ToggleDefault
                labelText="Imprimir Tributos por Produto?"
                setChecked={formData.flg_exibe_trib_prod.value}
                onSwitch={() => {
                  setValue(
                    'flg_exibe_trib_prod',
                    !formData.flg_exibe_trib_prod.value,
                  );
                  setFormData({
                    ...formData,
                    flg_exibe_trib_prod: {
                      ...formData.flg_exibe_trib_prod,
                      value: !formData.flg_exibe_trib_prod.value,
                    },
                  });
                }}
              />
            </Col>
            <Col md={4} sm={12}>
              <ToggleDefault
                labelText="Imprimir Documentos Referenciados?"
                setChecked={formData.flg_exibe_dados_ref.value}
                onSwitch={() => {
                  setValue(
                    'flg_exibe_dados_ref',
                    !formData.flg_exibe_dados_ref.value,
                  );
                  setFormData({
                    ...formData,
                    flg_exibe_dados_ref: {
                      ...formData.flg_exibe_dados_ref,
                      value: !formData.flg_exibe_dados_ref.value,
                    },
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <div>
              <Separator labelText="Geral" color="#6B778c" />
            </div>
            <Col md={3} sm={12}>
              <InputSelectNovo
                label="Forma de Emissão"
                placeholder="Selecione a forma"
                name="tipo_emissao_nfe"
                register={register}
                isError={!!errors.tipo_emissao_nfe}
                control={control}
                options={formaEmissao}
                changeSelected={(selected) => {
                  setValue('tipo_emissao_nfe', selected);
                }}
              />
            </Col>
            <Col md={6} sm={12}>
              <InputTextNovo
                label="Motivo"
                minLength={15}
                maxLength={500}
                placeholder="Informe o motivo"
                name="des_motivo_contingencia"
                register={register}
                toLowerCase={false}
                disabled={disableMotivoInicio || tipoEmissaoNfe === undefined}
                isError={!!errors.des_motivo_contingencia}
                control={control}
              />
            </Col>
            <Col md={3} sm={12}>
              <InputDate
                label="Início"
                name="dta_ini_contingencia"
                register={register}
                type="datetime-local"
                control={control}
                disabled={disableMotivoInicio || tipoEmissaoNfe === undefined}
                isError={!!errors.dta_ini_contingencia}
              />
            </Col>
          </Row>
        </FormDefault>
      )}
    </Container>
  );
};

export default ParametrizacaoNfe;
