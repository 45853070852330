import styled from 'styled-components';
import { Button as btn } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  @media screen and (max-width: 760px) {
    .toggle-box {
      margin-top: 4px;
    }
  }

  .casasContainer {
    min-width: 150px;
  }
  .uploadAarquivoCoteiner {
  }

  .btnLimiteCredito {
    float: right;
    padding: 8px 8px 8px 8px;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    /* margin-bottom: 5px; */
    transition: 0.4s;
    color: #424242;
  }
  .btnLimiteCredito:hover {
    background-color: #f4f5f7;
    color: #000;
    transition: 0.4s;
  }
  .accordion-collapse {
    border-color: transparent;
    border: none !important;
  }
  .alterados--content {
    /* padding: 0px 0px 0px 0px; */
  }
  .flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .item {
    width: auto;
    /* background: tomato;
    text-align: center;
    font-size: 1.5em; */
  }

  /* .btn--cargas {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  .btnTestar {
    background-color: red;
    width: 100%;
    height: 40px;
    margin-top: 30px;
    background: #8850bf;
    color: #fff;
    border: none;
    border-radius: 3px;
    transition: filter 550ms;

    &:hover {
      filter: brightness(0.8);
    }
  }
  .invalidInputFile label {
    border-bottom: 3px solid #cf1919;
    box-shadow: 0px 6px 50px 5px rgba(0, 0, 0, 0.09);
  }
  .invalidInput .css-rij6sy-ValueContainer {
    border-bottom: 2px solid #cf1919;
  }
`;

export const ButtonRow = styled.button`
  flex: 1;
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: #f4f5f7 !important;
    color: #000;
    transition: 0.4s;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;

  .iconStyle {
    font-size: 12px;
    margin-top: -3px;
    margin-right: 2px;
  }
`;

export const Button = styled(btn)`
  width: 100%;
  background-color: ${(props) => props.color};
  box-shadow: transparent !important;
  border-color: ${(props) => props.color};
  border-radius: 0px 0px 10px 10px;

  &:active {
    background-color: ${(props) => props.focusColor};
  }
  &:active:focus {
    background-color: ${(props) => props.focusColor};
  }
  &:focus {
    background-color: ${(props) => props.focusColor};
    box-shadow: none;
  }
  &:nth-child(2) {
    margin-left: 4px;
  }
`;
